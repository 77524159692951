// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buy-js": () => import("./../../../src/pages/buy.js" /* webpackChunkName: "component---src-pages-buy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-2-js": () => import("./../../../src/pages/learn2.js" /* webpackChunkName: "component---src-pages-learn-2-js" */),
  "component---src-pages-learn-3-js": () => import("./../../../src/pages/learn3.js" /* webpackChunkName: "component---src-pages-learn-3-js" */),
  "component---src-pages-learn-4-js": () => import("./../../../src/pages/learn4.js" /* webpackChunkName: "component---src-pages-learn-4-js" */),
  "component---src-pages-learn-js": () => import("./../../../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-social-js": () => import("./../../../src/pages/social.js" /* webpackChunkName: "component---src-pages-social-js" */)
}

